import { graphql } from 'gatsby'
import * as React from 'react'

import Content from '../components/Content'
import Main from '../components/Main'
import Seo from '../components/seo'

const ParturiKampaamoPage = ({ data, location }) => {
  const { page } = data

  return (
    <Main>
      <Seo metadata={page.metadata} />
      <Content content={page.content} />
    </Main>
  )
}

export default ParturiKampaamoPage

export const query = graphql`
  query ParturiKampaamoQuery {
    page: sanityPage(id: { eq: "-95171992-74a1-5117-9d16-2897386bc30c" }) {
      ...contentFragment
    }
  }
`
